'use client';

import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

import { toBool } from '@/utils/stringUtils';

const App = dynamic(() => import('@/modules/routing/router').then((mod) => mod.default), {
  ssr: false,
});

const MSW_ENABLED =
  toBool(process.env.NEXT_PUBLIC_MSW_API_MOCKING) && process.env.NODE_ENV === 'development';

export default function Page() {
  const [shouldRender, setShouldRender] = useState(!MSW_ENABLED);
  useEffect(() => {
    async function initMocks() {
      await import('../../mocks');
      setShouldRender(true);
    }

    if (MSW_ENABLED) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      initMocks();
    }
  }, []);

  if (!shouldRender) {
    return null;
  }
  return <App />;
}
